import React from "react";
import ArticleContent from "../components/ArticleContent";
import NotFoundPage from "./NotFoundPage";
import axios from "axios";
import { useParams } from "react-router-dom";

const ArticlePage = (props) => {
  let { name } = useParams(); 
  console.log("match " + name)

  let [responseData, setResponseData] = React.useState("");
  const fetchData = React.useCallback(() => {
    axios({
      method: "GET",
      url: `https://hono-example-blog.thuvu3333.workers.dev/articles/${name}`,
    })
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (responseData) {
    return (
      <>
        <ArticleContent article={responseData} />
      </>
    );
  }
  return (
    <>
      <h1>Article:</h1>
    </>
  );


};

export default ArticlePage;
