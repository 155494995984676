import React from "react";
import axios from "axios";
import ArticlesList from "../components/ArticlesList";
import { useParams } from "react-router-dom";


function ArticlesListPage() {
  let [responseData, setResponseData] = React.useState("");
  let { category } = useParams(); 

  const fetchData = React.useCallback(() => {
    axios({
      method: "GET",
      url: `https://hono-example-blog.thuvu3333.workers.dev/article_categories/${category}`,
    })
      .then((response) => {
        setResponseData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (responseData) {
    return (
      <>
        <h1>Articles</h1>
        <ArticlesList articles={responseData} />
      </>
    );
  }
  return (
    <>
      <h1>Articles</h1>
    </>
  );
}
export default ArticlesListPage;
