import React from "react";

const ArticleContent = (props) => {

  let article = props.article;
  return (
    <>
      <h1>{article.title}</h1>
      {article.content.map((paragraph, key) => (
        <>
        <p>{paragraph.en}</p>
        <i>{paragraph.vi}</i>
        <br></br>
        <br></br>
        </>
      ))}
    </>
  );
};

export default ArticleContent;



