import React from "react";
import { Link } from "react-router-dom";

const ArticlesList = (props) => {
  console.log("ArticleListx " + typeof props.articles);

  let articles = props.articles;
  console.log("ArticleList " + typeof articles);
  return (
    <>
     { articles.map((article, key) => (
        <Link
          className="article-list-item"
          key={key}
          to={`/articles/${article.id}`}
        >
          <ul>
            <li>
              <h3>{article.title}</h3>
              <p>{article.brief}...</p>
            </li>
          </ul>
        </Link>
      ))}
    </>
  );
};

export default ArticlesList;
