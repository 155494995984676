import React from "react";
import { Link } from "react-router-dom";

const ArticlesCategory = (props) => {

  let categories = props.categories;
  return (
    <>
     { categories.map((category, key) => (
        <Link
          className="article-list-item"
          key={key}
          to={`/articles-list/${category.id}`}
        >
          <ul>
            <li>
              <h3>{category.title}</h3>
            </li>
          </ul>
        </Link>
      ))}
    </>
  );
};

export default ArticlesCategory;
